@charset "utf-8";
/* CSS Document */

@import "import.scss";

/* COMMON PARTS-------------------------------------------*/
.pagetype-home {
  .article-list {
    position: relative;
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 769px) {
  .pagetype-home {
    .article-list {
      max-width: 1090px;
      margin: 0 auto 40px;
    }
  }
}

.home-mds {
  @include mds-type01SP;
}
@media screen and (min-width: 769px) {
  .home-mds {
    @include mds-type01PC;
  }
}

.zin1 {
  z-index: 1;
}

/* メインビジュアル-------------------------------------------*/
.mv-area {
  position: relative;
  opacity: 0;
  transition: all 0.3s ease;
  overflow: hidden;
  .slider-box {
    display: block;
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    padding-bottom: 30px;
    .slide {
      margin: 0;
      overflow: hidden;
      text-align: center;
      img {
        width: 100%;
      }
    }
  }
  .swiper-pagination {
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background: $mainBlkColor;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background: $pointColor;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}
@media screen and (min-width: 769px) {
  .mv-area {
    margin-bottom: 0;
    padding-top: 40px;
    .slider-box {
      padding-bottom: 40px;
      .slider-inner {
        max-width: 680px;
      }
      .slide {
        padding: 0;
        position: relative;
        border-radius: 30px;
        overflow: hidden;
        a:hover {
          img {
            transition: all 0.3s ease;
            transform: scale(1.1,1.1);
          }
        }
      }
    }
    .swiper-pagination {
      bottom: 0;
      .swiper-pagination-bullet {
      }
      .swiper-pagination-bullet-active {
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      transition: all 0.3s ease;
      &::after {
        content: "";
      }
    }
    .swiper-button-prev {
      left: 22.2vw;
      background-image: url(../images/home/mv_left.svg);
      &:hover {
        transform: translateX(-5px);
      }
    }
    .swiper-button-next {
      right: 22.2vw;
      background-image: url(../images/home/mv_right.svg);
      &:hover {
        transform: translateX(5px);
      }
    }
  }
}

/* ナビゲーションボタン-------------------------------------------*/
.home-nav-area {
  padding: 20px 0 30px;
  position: relative;
  .icon-nav {
    li {
      width: 33%;
      margin-bottom: 14px;
      position: relative;
      .circle-wrapper {
        width: 18vw;
        margin: 0 auto;
      }
      &.nav-shopguide {
        .icon-btn {
          width: 30px;
          height: 30px;
        }
      }
      &.nav-shopblog{
        .icon-btn {
          width: 32px;
          height: 28px;
        }
      }
      &.nav-event{
        .icon-btn {
          width: 30px;
          height: 28px;
        }
      }
      &.nav-service{
        .icon-btn {
          width: 30px;
          height: 30px;
        }
      }
      &.nav-access{
        .icon-btn {
          width: 36px;
          height: 21px;
        }
      }
      &.nav-hour{
        .icon-btn {
          width: 30px;
          height: 30px;
        }
      }
      &.nav-card{
        .icon-btn {
          width: 30px;
          height: 28px;
        }
      }
      &.nav-garden{
        .icon-btn {
          width: 30px;
          height: 30px;
        }
      }
      &.nav-recruit{
        .icon-btn {
          width: 34px;
          height: 31px;
        }
      }
    }
    .txt {
      font-size: 11px;
      margin-top: 5px;
      text-align: center;
      line-height: 1.5;
      font-weight: bold;
    }
  }
  .deco {
    .deco01 {
      width: 285px;
      top: -24%;
      right: 13%;
    }
    .deco02 {
      width: 200px;
      bottom: 23%;
      left: -10%;
    }
    .deco03 {
      width: 310px;
      bottom: -17%;
      right: 0;
    }
  }
}
@media screen and (min-width: 769px) {
  .home-nav-area {
    padding: 45px 0 105px;
    .icon-nav {
      justify-content: center;
      li {
        width: 120px;
        margin-bottom: 0;
        .circle-wrapper {
          width: 80px;
        }
        &.nav-shopguide {
          .icon-btn {
          }
        }
        &.nav-shopblog{
          .icon-btn {
          }
        }
        &.nav-event{
          .icon-btn {
          }
        }
        &.nav-service{
          .icon-btn {
          }
        }
        &.nav-access{
          .icon-btn {
          }
        }
        &.nav-hour{
          .icon-btn {
          }
        }
        &.nav-card{
          .icon-btn {
          }
        }
        &.nav-garden{
          .icon-btn {
          }
        }
        &.nav-recruit{
          .icon-btn {
          }
        }
      }
      .txt {
        font-size: 12px;
        margin-top: 7px;
      }
    }
    .deco {
      .deco01 {
        width: 349px;
        top: -54%;
        left: -7%;
      }
      .deco02 {
        width: 260px;
        bottom: -55%;
        left: 15%;
      }
      .deco03 {
        width: 260px;
        bottom: 10%;
        right: 32%;
      }
      .deco04 {
        width: 375px;
        bottom: -51%;
        right: 0;
      }
    }
  }
}
.nav-shopblog {
  position: relative;
}
.icon-new {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  @include fontLatin;
  font-size: 11px;
  @media screen and (min-width: 769px) {
  }
}


/* EVENT&NEWS-------------------------------------------*/
.event-area {
  .box-inner {
    margin: 0;
  }
  .home-mds {
    color: #c0de40;
    font-size: 11vw;
    margin-bottom: 0;
    line-height: 0.6;
    z-index: 2;
    position: relative;
  }
  .event-wrapper {
    border-radius: 0px 40px 0px 0px;
    background: #f6fdd8;
    padding: 40px 20px 125px;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  .deco {
    .deco01 {
      width: 165px;
      bottom: 18.8%;
      right: -13%;
    }
    .deco02 {
      width: 211px;
      bottom: -2%;
      left: -24%;
    }
  }
}
@media screen and (min-width: 769px) {
  .event-area {
    .box-inner {
      padding: 0;
      max-width: 100%;
    }
    .home-mds {
      font-size: 120px;
      max-width: 1090px;
      margin: 0 auto;
    }
    .event-wrapper {
      padding: 60px 20px 200px;
    }
    .deco {
      .deco01 {
        width: 320px;
        bottom: 4.8%;
        right: auto;
        left: -3.5%;
      }
      .deco02 {
        width: 250px;
        bottom: 36%;
        left: auto;
        right: 17%;
      }
    }
  }
}

/* SHOP BLOG-------------------------------------------*/
.blog-area {
  margin-top: -10vh;
  .box-inner {
    margin: 0;
  }
  .home-mds {
    color: #ff9202;
    font-size: 12.5vw;
    text-align: right;
    margin-bottom: 0;
    line-height: 0.6;
    z-index: 2;
    position: relative;
  }
  .blog-wrapper {
    border-radius: 40px 0px 0px 0px;
    background: #fed;
    padding: 40px 20px 60px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    .article-list {
    }
  }
  .deco {
    .deco01 {
      width: 285px;
      top: 0.8%;
      right: -17.8%;
    }
    .deco02 {
      width: 160px;
      bottom: 24%;
      left: -10%;
    }
    .deco03 {
      width: 175px;
      bottom: -30px;
      right: 0;
    }
  }
}
@media screen and (min-width: 769px) {
  .blog-area {
    margin-top: -100px;
    .box-inner {
      padding: 0;
      max-width: 100%;
    }
    .home-mds {
      font-size: 120px;
      max-width: 1090px;
    margin: 0 auto;
    }
    .blog-wrapper {
      padding: 60px 20px 40px;
      .article-list {
      }
    }
    .deco {
      .deco01 {
        width: 260px;
        top: auto;
        right: auto;
        bottom: 5%;
        left: -2%;
      }
      .deco02 {
        width: 349px;
        bottom: auto;
        left: auto;
        top: 1%;
        right: 1%;
      }
      .deco03 {
        width: 292px;
      }
    }
  }
  .article-list.blog {
    margin-bottom: 30px;
  }
}

/* INFORMATION-------------------------------------------*/
.info-area {
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 80px;
  .home-mds {
    color: $pointColor;
    font-size: 38px;
  }
  .info-list {
    padding: 20px 0;
    border-top: 1px solid $pointColor;
    dt {
      margin-bottom: 2px;
      font-size: 11px;
      color: #afafaf;
      margin-bottom: 10px;
      .tag {
        border-radius: 12px;
        background: $mainBlkColor;
        color: #fff;
        text-align: center;
        padding: 4px 15px;
        margin-right: 10px;
      }
    }
    dd {
      @media screen and (max-width: 769px) {
        .ttl-box {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
    &:last-child {
      border-bottom: 1px solid $pointColor;
    }
  }
  .deco {
    .deco01 {
      width: 205px;
      top: 0;
      left: 0;
    }
    .deco02 {
      width: 166px;
      top: 90px;
      right: -30px;
    }
    .deco03 {
      width: 204px;
      bottom: 70px;
      left: -20px;
    }
  }
}
@media screen and (min-width: 769px) {
  .info-area {
    padding-top: 70px;
    .home-mds {
      font-size: 70px;
      margin-bottom: 10px;
    }
    .info-list {
      display: flex;
      padding: 40px 0;
      dt {
        margin-bottom: 0;
        margin-right: 20px;
        .tag {
          margin-right: 20px;
        }
      }
      dd {
        flex: 1;
        margin-right: 20px;
        a {
        }
      }
      &:last-child {
      }
    }
    .deco {
      .deco01 {
        width: 324px;
      }
      .deco02 {
        width: 316px;
        bottom: 7%;
        top: auto;
        right: auto;
        left: 0;
      }
      .deco03 {
        width: 250px;
        bottom: auto;
        top: -8%;
        right: -5%;
        left: auto;
      }
      .deco04 {
        width: 260px;
        bottom: 3%;
        right: -3%;
      }
    }
  }
}